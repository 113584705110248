import rolePermissionService from "@services/rolePermissionService";

export default {
    data() {
        return {
            permissionList: [],
            currentUser: null,
            userParams: null,
            roleLoading: false,
            permissionConst: {
                forecast: {
                    edit: "FORECAST_EDIT",                                // droit de créer ou modifier une fiche de prévision
                    editOther: "FORECAST_EDIT_OTHER",                     // droit de créer ou modifier une fiche de prévision d'un CEI différent
                    manage: "FORECAST_MANAGE",                            // droit de supprimer une fiche de prévision
                    view: "FORECAST_VIEW",                                // droit de voir une fiche de prévision non validée, non archivée
                    viewValidated: "FORECAST_VIEW_VALIDATED",             // droit de voir une fiche de prévision validée
                    viewArchived: "FORECAST_VIEW_ARCHIVED",               // droit de voir une fiche de prévision archivée
                    viewOther: "FORECAST_VIEW_OTHER",                     // droit de voir une fiche de prévision non validée, non archivée d'un CEI différent
                    viewValidatedOther: "FORECAST_VIEW_VALIDATED_OTHER",  // droit de voir une fiche de prévision validée d'un CEI différent
                    viewArchivedOther: "FORECAST_VIEW_ARCHIVED_OTHER",    // droit de voir une fiche de prévision archivée d'un CEI différent
                },
                link: {
                    edit: "LINK_EDIT",                                    // droit de créer ou modifier une fiche de liaison
                    editOther: "LINK_EDIT_OTHER",                         // droit de créer ou modifier une fiche de liaison d'un CEI différent
                    manage: "LINK_MANAGE",                                // droit de supprimer une fiche de liaison
                    view: "LINK_VIEW",                                    // droit de voir une fiche de liaison non validée, non archivée
                    viewValidated: "LINK_VIEW_VALIDATED",                 // droit de voir une fiche de liaison validée
                    viewArchived: "LINK_VIEW_ARCHIVED",                   // droit de voir une fiche de liaison archivée
                    viewOther: "LINK_VIEW_OTHER",                         // droit de voir une fiche de liaison non validée, non archivée d'un CEI différent
                    viewValidatedOther: "LINK_VIEW_VALIDATED_OTHER",      // droit de voir une fiche de liaison validée d'un CEI différent
                    viewArchivedOther: "LINK_VIEW_ARCHIVED_OTHER",        // droit de voir une fiche de liaison archivée d'un CEI différent
                },
                classicDailyOverview: {
                    view: "CLASSIC_DAILY_OVERVIEW_VIEW",              // droit de voir les synthèses quotidiennes classiques non archivées
                    archived: "CLASSIC_DAILY_OVERVIEW_VIEW_ARCHIVED",      // droit de voir les synthèses quotidiennes classiques archivées
                },
                modernDailyOverview: {
                    view: "MODERN_DAILY_OVERVIEW_VIEW",               // droit de voir les synthèses quotidiennes modernes non archivées
                    archived: "MODERN_DAILY_OVERVIEW_VIEW_ARCHIVED",       // droit de voir les synthèses quotidiennes modernes archivées
                },
                weeklyOverview: {
                    view: "WEEKLY_OVERVIEW_VIEW",                     // droit de voir les synthèses hebdomadaires non archivées
                    archived: "WEEKLY_OVERVIEW_VIEW_ARCHIVED",             // droit de voir les synthèses hebdomadaires archivées
                },
                healthcareReview: {
                    edit: "HEALTHCARE_REVIEW_EDIT",                   // droit de modifier les bilans de l'organisation des conditions de travail des personnels
                    manage: "HEALTHCARE_REVIEW_MANAGE",               // droit de supprimer les bilans de l'organisation des conditions de travail des personnels
                    view: "HEALTHCARE_REVIEW_VIEW",                   // droit de voir les bilans de l'organisation des conditions de travail des personnels
                    viewArchived: "HEALTHCARE_REVIEW_VIEW_ARCHIVED",  // droit de voir les bilans de l'organisation des conditions de travail des personnels archivés
                },
                circuitReview: {
                    edit: "CIRCUIT_REVIEW_EDIT",                      // droit de modifier les bilans de circuits
                    manage: "CIRCUIT_REVIEW_MANAGE",                  // droit de supprimer les bilans de circuits
                    view: "CIRCUIT_REVIEW_VIEW",                      // droit de voir les bilans de circuits
                    viewArchived: "CIRCUIT_REVIEW_VIEW_ARCHIVED",     // droit de voir les bilans de circuits archivés
                },
                crisisReview: {
                    edit: "CRISIS_REVIEW_EDIT",                       // droit de modifier les bilans de crises
                    manage: "CRISIS_REVIEW_MANAGE",                   // droit de supprimer les bilans de crises
                    view: "CRISIS_REVIEW_VIEW",                       // droit de voir les bilans de crises
                    viewArchived: "CRISIS_REVIEW_VIEW_ARCHIVED",      // droit de voir les bilans de crises archivés
                },
                formationReview: {
                    edit: "FORMATION_REVIEW_EDIT",                    // droit de modifier les bilans de formation
                    manage: "FORMATION_REVIEW_MANAGE",                // droit de supprimer les bilans de formation
                    view: "FORMATION_REVIEW_VIEW",                    // droit de voir les bilans de formation
                    viewArchived: "FORMATION_REVIEW_VIEW_ARCHIVED",   // droit de voir les bilans de formation archivés
                },
                equipmentReview: {
                    edit: "EQUIPMENT_REVIEW_EDIT",                    // droit de modifier les bilans de formation
                    manage: "EQUIPMENT_REVIEW_MANAGE",                // droit de supprimer les bilans de formation
                    view: "EQUIPMENT_REVIEW_VIEW",                    // droit de voir les bilans de formation
                    viewArchived: "EQUIPMENT_REVIEW_VIEW_ARCHIVED",   // droit de voir les bilans de formation archivés
                },
                admin: {
                    edit: "ADMIN_EDIT",                               // droit de modifier les paramètres d'administration
                    manage: "ADMIN_MANAGE",                           // droit de supprimer des données des paramètres d'administration
                    view: "ADMIN_VIEW"                                // droit de voir les paramètres d'administration
                },
                intervention: {
                    delete: "INTERVENTION_DELETE"
                },
                ceiAgent: {
                    manage : "CEI_AGENTS_MANAGE"
                }
            }
        }
    },

    created() {
        this.roleLoading = true;
        this.currentUser = this.$store.state.userInformation;
        this.userParams = this.$store.state.userParams;

        let p1 = rolePermissionService.findAllByCurrentUser();
        p1.then(data => this.permissionList = data);
        Promise.all([p1]).then(() => this.roleLoading = false);
    },

    methods: {
        hasPermission(permission) {
            return this.permissionList.includes(permission);
        },

        isUserGroupAdmin() {
            return this.currentUser.userGroupTechName === 'ADMIN';
        },
        isUserGroupDirection() {
            return this.currentUser.userGroupTechName === 'DIRECTION';
        },
        isUserGroupDirectionAGER() {
            return this.currentUser.userGroupTechName === 'DIRECTION_AGER';
        },
        isUserGroupCadreN1AGER() {
            return this.currentUser.userGroupTechName === 'CADRE_N1_AGER';
        },
        isUserGroupUgfAGER() {
            return this.currentUser.userGroupTechName === 'UGF_AGER';
        },
        isUserGroupDirectionUER() {
            return this.currentUser.userGroupTechName === 'DIRECTION_UER';
        },
        isUserGroupDirectionCEI() {
            return this.currentUser.userGroupTechName === 'DIRECTION_CEI';
        },
        isUserGroupRiCEI() {
            return this.currentUser.userGroupTechName === 'RI_CEI';
        },
        isUserGroupDirectionSTT() {
            return this.currentUser.userGroupTechName === 'DIRECTION_STT';
        },
        isUserGroupSTT() {
            return this.currentUser.userGroupTechName === 'STT';
        },
        isUserGroupMiPOLEX() {
            return this.currentUser.userGroupTechName === 'MIPOLEX';
        },
        isUserGroupSGD() {
            return this.currentUser.userGroupTechName === 'SGD';
        },
        isUserGroupSGPR() {
            return this.currentUser.userGroupTechName === 'SGPR';
        },
        isUserGroupSMR() {
            return this.currentUser.userGroupTechName === 'SMR';
        },

        isHierarchy() {
            return this.isUserGroupDirectionAGER()
                || this.isUserGroupCadreN1AGER()
                || this.isUserGroupDirectionUER()
                || this.isUserGroupDirectionCEI()
                || this.isUserGroupRiCEI()
        },

        isMyCEI(data) {
            return this.currentUser.ceiId && this.currentUser.ceiId === data.ceiId;
        },
        isMyUER(data) {
            return this.currentUser.uerId && this.currentUser.uerId === data.uerId;
        },
        isMyAGER(data) {
            return this.currentUser.agerId && this.currentUser.agerId === data.agerId;
        },

        isOtherCEI(data) {
            return this.currentUser.ceiId && this.currentUser.ceiId !== data.ceiId;
        },
        isOtherUER(data) {
            return this.currentUser.uerId && this.currentUser.uerId !== data.uerId;
        },
        isOtherAGER(data) {
            return this.currentUser.agerId && this.currentUser.agerId !== data.agerId;
        },

        isRelatedToUser(data) {
            return this.isMyCEI(data) || this.isMyUER(data) || this.isMyAGER(data);
        },

        hasAger() {
            return !!this.currentUser.agerId
        },

        //    INTERVENTIONS
        canComment(intervention) {
            return this.isUserGroupUgfAGER()
                || this.isUserGroupDirectionCEI()
                || this.isUserGroupCadreN1AGER()
                || this.isUserGroupDirectionUER()
                || this.isUserGroupDirectionAGER()
                || this.isUserGroupAdmin()
                || intervention.userId === this.currentUser.userId;
        },
        canUpdateIntervention(intervention) {
            return this.currentUser.userId === intervention.userId;
        },
        canDeleteIntervention(){
            return this.hasPermission(this.permissionConst.intervention.delete)
        },
        //    SHEETS

        canViewAtLeastOneSheet() {
            return this.canViewForecast()
                || this.canViewArchivedForecast()
                || this.canViewLink()
                || this.canViewArchivedLink();
        },

        canEdit(data) {
            return !data.year // Non modifiable si archivé
                && !data.dateValidated // Non modifiable si validé
                && (this.isUserGroupRiCEI() || this.isUserGroupDirectionCEI() || this.isUserGroupDirectionUER() || this.isUserGroupDirectionAGER() || this.isUserGroupCadreN1AGER());
        },

        canValidate(data) {
            return this.canEdit(data);
        },

        isSheetCreator(data) {
            return data.createdById === this.currentUser.userId;
        },

        // FORECAST

        canViewForecast() {
            if (this.isUserGroupAdmin()) return true;
            return (this.hasPermission(this.permissionConst.forecast.view) || this.hasPermission(this.permissionConst.forecast.viewOther));
        },
        canViewValidatedForecast() {
            if (this.isUserGroupAdmin()) return true;
            return (this.hasPermission(this.permissionConst.forecast.viewValidated) || this.hasPermission(this.permissionConst.forecast.viewValidatedOther));
        },
        canViewArchivedForecast() {
            if (this.isUserGroupAdmin()) return true;
            return (this.hasPermission(this.permissionConst.forecast.viewArchived) || this.hasPermission(this.permissionConst.forecast.viewArchivedOther));
        },
        canViewThisForecast(data) {
            if (this.isUserGroupAdmin()) return true;
            return (this.hasPermission(this.permissionConst.forecast.view) && this.isRelatedToUser(data))
                || (this.hasPermission(this.permissionConst.forecast.viewOther) && !this.isRelatedToUser(data));
        },
        canViewThisValidatedForecast(data) {
            if (this.isUserGroupAdmin()) return true;
            return (this.hasPermission(this.permissionConst.forecast.viewValidated) && this.isRelatedToUser(data))
                || (this.hasPermission(this.permissionConst.forecast.viewValidatedOther) && !this.isRelatedToUser(data));
        },
        canViewThisArchivedForecast(data) {
            if (this.isUserGroupAdmin()) return true;
            return (this.hasPermission(this.permissionConst.forecast.viewArchived) && this.isRelatedToUser(data))
                || (this.hasPermission(this.permissionConst.forecast.viewArchivedOther) && !this.isRelatedToUser(data));
        },
        canOnlyViewArchivedForecasts() {
            return !this.canViewForecast()
                && !this.canViewValidatedForecast()
                && this.canViewArchivedForecast();
        },
        canDeleteForecast(data) {
            if (this.isUserGroupAdmin()) return true;
            if (!this.canEditForecast(data)) return false;
            if (data.dateValidated) return false;
            return this.hasPermission(this.permissionConst.forecast.manage);
        },
        canDeleteForecasts(data) {
            for (let forecast of data) {
                if (!this.canDeleteForecast(forecast)) return false;
            }
            return true;
        },
        canCreateForecast() {
            if (this.isUserGroupAdmin()) return true;
            return this.hasPermission(this.permissionConst.forecast.edit);
        },
        canEditForecast(data) {
            if (this.isUserGroupAdmin()) return true;
            if (this.hasPermission(this.permissionConst.forecast.edit) && this.isRelatedToUser(data)
                || this.hasPermission(this.permissionConst.forecast.editOther) && !this.isRelatedToUser(data)) {
                return this.canEdit(data) && this.isSheetCreator(data);
            }
            return false;
        },
        canValidateForecast(data) {
            if (data.validated) return false;
            if (this.isUserGroupAdmin()) return true;
            if (this.hasPermission(this.permissionConst.forecast.edit) && this.isRelatedToUser(data)
                || this.hasPermission(this.permissionConst.forecast.editOther) && !this.isRelatedToUser(data)) {
                return this.canValidate(data) && this.isSheetCreator(data);
            }
            return false;
        },
        canCommentForecast(data) {
            if (this.isUserGroupAdmin()) return true;
            return this.isHierarchy()
                && data.dateValidated !== undefined
                && data.dateValidated !== null
                && this.isRelatedToUser(data);
        },

        // LINK

        canViewLink() {
            if (this.isUserGroupAdmin()) return true;
            return (this.hasPermission(this.permissionConst.link.view) || this.hasPermission(this.permissionConst.link.viewOther));
        },
        canViewValidatedLink() {
            if (this.isUserGroupAdmin()) return true;
            return (this.hasPermission(this.permissionConst.link.viewValidated) || this.hasPermission(this.permissionConst.link.viewValidatedOther));
        },
        canViewArchivedLink() {
            if (this.isUserGroupAdmin()) return true;
            return (this.hasPermission(this.permissionConst.link.viewArchived) || this.hasPermission(this.permissionConst.link.viewArchivedOther));
        },
        canViewThisLink(data) {
            if (this.isUserGroupAdmin()) return true;
            return (this.hasPermission(this.permissionConst.link.view) && this.isRelatedToUser(data))
                || (this.hasPermission(this.permissionConst.link.viewOther) && !this.isRelatedToUser(data));
        },
        canViewThisValidatedLink(data) {
            if (this.isUserGroupAdmin()) return true;
            return (this.hasPermission(this.permissionConst.link.viewValidated) && this.isRelatedToUser(data))
                || (this.hasPermission(this.permissionConst.link.viewValidatedOther) && !this.isRelatedToUser(data));
        },
        canViewThisArchivedLink(data) {
            if (this.isUserGroupAdmin()) return true;
            return (this.hasPermission(this.permissionConst.link.viewArchived) && this.isRelatedToUser(data))
                || (this.hasPermission(this.permissionConst.link.viewArchivedOther) && !this.isRelatedToUser(data));
        },
        canOnlyViewArchivedLink() {
            return !this.canViewLink()
                && !this.canViewValidatedLink()
                && this.canViewArchivedLink();
        },
        canDeleteLink(data) {
            if (this.isUserGroupAdmin()) return true;
            if (!this.canEditLink(data)) return false;
            if (data.dateValidated) return false;
            return this.hasPermission(this.permissionConst.link.manage);
        },
        canDeleteLinks(data) {
          for (let link of data) {
              if (!this.canDeleteLink(link)) return false;
          }
          return true;
        },
        canCreateLink() {
            if (this.isUserGroupAdmin()) return true;
            return this.hasPermission(this.permissionConst.link.edit);
        },
        canEditLink(data) {
            if (this.isUserGroupAdmin()) return true;
            if (this.hasPermission(this.permissionConst.link.edit) && this.isRelatedToUser(data)
                || this.hasPermission(this.permissionConst.link.editOther) && !this.isRelatedToUser(data)) {
                return this.canEdit(data) && this.isSheetCreator(data);
            }
            return false;
        },
        canValidateLink(data) {
            if (data.validated) return false;
            if (this.isUserGroupAdmin()) return true;
            if (this.hasPermission(this.permissionConst.link.edit) && this.isRelatedToUser(data)
                || this.hasPermission(this.permissionConst.link.editOther) && !this.isRelatedToUser(data)) {
                return this.canValidate(data) && this.isSheetCreator(data);
            }
            return false;
        },

        //    OVERVIEWS

        canViewAtLeastOneOverview() {
            return this.canViewCD() || this.canViewArchivedCD() ||
                this.canViewMD() || this.canViewArchivedMD() ||
                this.canViewW() || this.canViewArchivedW();
        },


        // CLASSIC DAILY OVERVIEW

        canViewCD() {
            return this.hasPermission(this.permissionConst.classicDailyOverview.view);
        },

        canViewArchivedCD() {
            return this.hasPermission(this.permissionConst.classicDailyOverview.archived);
        },

        canOnlyViewArchivedCD() {
            return !this.canViewCD() && this.canViewArchivedCD();
        },

        // MODERN DAILY OVERVIEW

        canViewMD() {
            return this.hasPermission(this.permissionConst.modernDailyOverview.view);
        },

        canViewArchivedMD() {
            return this.hasPermission(this.permissionConst.modernDailyOverview.archived);
        },

        canOnlyViewArchivedMD() {
            return !this.canViewMD() && this.canViewArchivedMD();
        },

        // WEEKLY OVERVIEW

        canViewW() {
            return this.hasPermission(this.permissionConst.weeklyOverview.view);
        },

        canViewArchivedW() {
            return this.hasPermission(this.permissionConst.weeklyOverview.archived);
        },

        canOnlyViewArchivedW() {
            return !this.canViewW() && this.canViewArchivedW();
        },


        //    REVIEWS

        canViewAtLeastOneReview() {
            return this.canViewHealthcare() || this.canViewHealthcareArchived() ||
                this.canViewCircuit() || this.canViewCircuitArchived() ||
                this.canViewCrisis() || this.canViewCrisisArchived() ||
                this.canViewFormation() || this.canViewFormationArchived() ||
                this.canViewEquipment() || this.canViewEquipmentArchived();
        },


        // HEALTHCARE REVIEW

        canViewHealthcare() {
            return this.hasPermission(this.permissionConst.healthcareReview.view);
        },
        canViewHealthcareArchived() {
            return this.hasPermission(this.permissionConst.healthcareReview.viewArchived);
        },
        canDeleteHealthcare() {
            return this.hasPermission(this.permissionConst.healthcareReview.manage);
        },
        canCreateHealthcare() {
            return this.hasPermission(this.permissionConst.healthcareReview.edit);
        },
        canEditHealthcare() {
            return this.hasPermission(this.permissionConst.healthcareReview.edit);
        },

        // CIRCUIT REVIEW

        canViewCircuit() {
            return this.hasPermission(this.permissionConst.circuitReview.view);
        },
        canViewCircuitArchived() {
            return this.hasPermission(this.permissionConst.circuitReview.viewArchived);
        },
        canDeleteCircuit() {
            return this.hasPermission(this.permissionConst.circuitReview.manage);
        },
        canCreateCircuit() {
            return this.hasPermission(this.permissionConst.circuitReview.edit);
        },
        canEditCircuit() {
            return this.hasPermission(this.permissionConst.circuitReview.edit);
        },

        // CRISIS REVIEW

        canViewCrisis() {
            return this.hasPermission(this.permissionConst.crisisReview.view);
        },
        canViewCrisisArchived() {
            return this.hasPermission(this.permissionConst.crisisReview.viewArchived);
        },
        canDeleteCrisis() {
            return this.hasPermission(this.permissionConst.crisisReview.manage);
        },
        canCreateCrisis() {
            return this.hasPermission(this.permissionConst.crisisReview.edit);
        },
        canEditCrisis() {
            return this.hasPermission(this.permissionConst.crisisReview.edit);
        },

        // FORMATION REVIEW

        canViewFormation() {
            return this.hasPermission(this.permissionConst.formationReview.view);
        },
        canViewFormationArchived() {
            return this.hasPermission(this.permissionConst.formationReview.viewArchived);
        },
        canDeleteFormation() {
            return this.hasPermission(this.permissionConst.formationReview.manage);
        },
        canCreateFormation() {
            return this.hasPermission(this.permissionConst.formationReview.edit);
        },
        canEditFormation() {
            return this.hasPermission(this.permissionConst.formationReview.edit);
        },

        // EQUIPMENT REVIEW

        canViewEquipment() {
            return this.hasPermission(this.permissionConst.equipmentReview.view);
        },
        canViewEquipmentArchived() {
            return this.hasPermission(this.permissionConst.equipmentReview.viewArchived);
        },
        canDeleteEquipment() {
            return this.hasPermission(this.permissionConst.equipmentReview.manage);
        },
        canCreateEquipment() {
            return this.hasPermission(this.permissionConst.equipmentReview.edit);
        },
        canEditEquipment() {
            return this.hasPermission(this.permissionConst.equipmentReview.edit);
        },


        //    ADMIN


        canViewAdmin() {
            return this.hasPermission(this.permissionConst.admin.view);
        },
        canEditAdmin() {
            return this.hasPermission(this.permissionConst.admin.edit);
        },
        canDeleteAdmin() {
            return this.hasPermission(this.permissionConst.admin.manage);
        },
        showAger(userGroup) {
            if (userGroup && userGroup.techName) switch (userGroup.techName) {
                case 'DIRECTION_AGER':
                    return true;
                case 'CADRE_N1_AGER':
                    return true;
                case 'UGF_AGER':
                    return true;
                case 'DIRECTION_UER':
                    return true;
                case 'DIRECTION_CEI':
                    return true;
                case 'RI_CEI':
                    return true;
                case 'AGENT':
                    return true;
                default:
                    return false;
            }
            return false;
        },
        showUer(userGroup) {
            if (userGroup && userGroup.techName) switch (userGroup.techName) {
                case 'DIRECTION_UER':
                    return true;
                case 'DIRECTION_CEI':
                    return true;
                case 'RI_CEI':
                    return true;
                case 'AGENT':
                    return true;
                default:
                    return false;
            }
            return false;
        },
        showCei(userGroup) {
            if (userGroup && userGroup.techName) switch (userGroup.techName) {
                case 'DIRECTION_CEI':
                    return true;
                case 'RI_CEI':
                    return true;
                case 'AGENT':
                    return true;
                default:
                    return false;
            }
            return false;
        },

        canViewCeiUserAdmin(){
            return this.hasPermission(this.permissionConst.ceiAgent.manage);
        },
        canManageCeiUserAdmin() {
            return this.hasPermission(this.permissionConst.ceiAgent.manage);
        },
    }
}