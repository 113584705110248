import scrollToTopMixin from "@/mixins/scrollToTopMixin";

export default {
    mixins:[scrollToTopMixin],
    data() {
        return {
            messages: [],
        }
    },
    methods: {
        error(content, reset = true, closable = true) {
            if (reset)
                this.resetMessages();
            this.messages.push({
                closable: closable, severity: "error", content: content
            });
            this.scrollToTop();
        },
        success(content, reset = true, closable = true) {
            if (reset)
                this.resetMessages();
            this.messages.push({
                closable: closable, severity: "success", content: content
            });
            this.scrollToTop();
        },
        resetMessages() {
            this.messages = [];
        },
        closeMessage(message) {
            this.messages = this.messages.filter(val => val !== message);
        },
    }
}
